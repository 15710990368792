import React, { useEffect, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { BiFilterAlt } from 'react-icons/bi'
import { MdSearch } from 'react-icons/md'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useHistory } from 'react-router-dom'

import { FaAngleDown } from 'react-icons/fa'
import Select, { components } from 'react-select'
import Language from '../models/Language'
import Specialization from '../models/Specialization'
import Palette from '../Palette'
import './css/HomePage.css'

function HomePage(props) {
    let history = useHistory()

    let [query, setQuery] = useState('')

    let [specializationSelection, setSpecializationSelection] = useState([])
    let [languageSelection, setLanguageSelection] = useState([])

    let [languageFrom, setLanguageFrom] = useState(null)
    let [languageTo, setLanguageTo] = useState(null)

    let [profession, setProfession] = useState(null)
    let [specializationsId, setSpecializationsId] = useState(null)

    let [selectedSpecializations, setSelectedSpecializations] = useState([])

    let [HPICertification, setHPICertification] = useState(null)

    let specializationModel = new Specialization()
    let languageModel = new Language()

    const runSearch = () => {
        history.push('/search/advanced?query=' + query)
        console.log('QUERY', query)
    }

    const runSearchAdvanced = () => {
        // console.log("SEARCHING", query, languageFrom, languageTo, specializationsId, profession)
        // console.log(
        //     `/search/advanced?query=${query}&language_id_from=${languageFrom.value}` +
        //     `&language_id_to=${languageTo.value}` +
        //     `&specializations_id=${specializationsId}` +
        //     `&profession=${profession}`
        // )



        history.push(
            `/search/advanced?query=${query}` +
            (languageFrom ? `&language_id_from=${languageFrom?.value}` : '') +
            (languageTo ? `&language_id_to=${languageTo?.value}` : '') +
            (profession ? `&profession=${profession}` : '') +
            (specializationsId ? `&specializations_id=${specializationsId}` : '') +
            (selectedSpecializations.length > 0
                ? `&specialization_ids=${selectedSpecializations}`
                : '') +

            (HPICertification ? `&certification=${HPICertification}` : '')
        )
    }

    useEffect(() => {
        getAllLanguages()
        getAllSpecialization()
    }, [])

    const getAllSpecialization = async () => {
        try {
            const specializations = await specializationModel.getAllAvailableSpecialization()
            console.log(specializations)

            setSpecializationSelection(specializations)
        } catch (e) {
            console.log(e)
        }
    }

    const getAllLanguages = async () => {
        try {
            const languages = await languageModel.getAllLanguages()

            languages.reverse()

            setLanguageSelection(
                languages.map((obj) => {
                    return {
                        value: obj.id,
                        label: obj.language,
                    }
                }),
            )

            console.log(languages)
        } catch (e) {
            console.log(e)
        }
    }

    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={'grey'} />
                </components.DropdownIndicator>
            )
        )
    }

    return (
        <Container style={{ minHeight: '100vh', backgroundColor: 'white', marginTop: 30 }}>
            <div
                style={{
                    marginTop: 'auto',
                    display: 'block',
                    backgroundColor: 'white',
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                    position: 'relative',
                }}
                className="text-center"
            >
                <h1 className="my-5">
                    Welcome to the Directory of Indonesian Translators and Interpreters
                </h1>
                <h5 className="my-5 w-75 container" style={{ color: '#9B9B9B' }}>
                    Find HPI-registered translators and interpreters.
                </h5>
                <Form.Control
                    value={query}
                    onChange={(e) => {
                        setQuery(e.target.value)
                    }}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            if(e.target.value !== ""){
                                runSearch()
                            }
                        
                        }
                    }}
                    type="text"
                    id="inputPassword5"
                    name="search"
                    placeholder="Search by name or HPI ID Number"
                    className="col-md-6 mx-auto mb-4"
                />
                <Button
                    style={{
                        fontFamily: 'Signika',
                        fontWeight: 'bold',
                        paddingTop: 10,
                        paddingBottom: 10,
                        width: '100%',
                        backgroundColor: Palette.PRIMARY,
                        borderColor: Palette.PRIMARY,
                        'border-radius': '20px',
                    }}
                    onClick={() => runSearch()}
                    className="col-md-6"
                >
                    SEARCH&nbsp;
                    <MdSearch size={20} />
                </Button>
                <Container>
                    <Row className="text-center">
                        <Col md={6} className="d-inline-block text-left m-auto">
                            <div className="my-4 text-center">
                                <BiFilterAlt size={20} /> or browse with more specific filters.
                            </div>
                            {/* 
                            <InputGroup className="my-4 d-block">
                                <div className="d-inline-block text-left">

                                    <Form.Check
                                        name="interest"
                                        type={'checkbox'}
                                        id={'checkboxHPI'}
                                        label={'Bersertifikat HPI'}
                                        checked={HPICertification}
                                        onClick={(e) => {
                                            let checkedData
                                            e.target.checked === false
                                                ? (checkedData = 0)
                                                : (checkedData = 1)
                                            setHPICertification(checkedData)
                                        }}
                                    />
                                </div>
                            </InputGroup> */}
                            <InputGroup className="my-4 d-block">
                                <div
                                    style={{
                                        fontFamily: 'Signika',
                                        fontWeight: 'bold',
                                        color: '#1F2041',
                                        marginBottom: 7,
                                        marginTop: 20,
                                    }}
                                >
                                    PROFESSION
                                </div>
                                <div
                                    style={{ display: 'grid' }}
                                    className="d-inline-block text-left"
                                >
                                    <Form.Check
                                        name="profession"
                                        type={'radio'}
                                        id={'Translator'}
                                        label={'Translator'}
                                        value={'Translator'}
                                        onChange={() => {
                                            setProfession('Translator')
                                        }}
                                    />
                                    <Form.Check
                                        name="profession"
                                        type={'radio'}
                                        id={'Interpreter'}
                                        label={'Interpreter'}
                                        onChange={() => {
                                            setProfession('Interpreter')
                                        }}
                                    />
                                    <Form.Check
                                        name="profession"
                                        type={'radio'}
                                        id={'Both'}
                                        label={'Both'}
                                        onChange={() => {
                                            setProfession('Both')
                                        }}
                                    />
                                </div>
                            </InputGroup>

                            <InputGroup className="my-4 d-block">
                                <div className="d-inline-block text-left w-100">
                                    <Form.Label
                                        htmlFor="basic-url"
                                        style={{
                                            fontFamily: 'Signika',
                                            fontWeight: 'bold',
                                            color: '#1F2041',
                                            marginBottom: 7,
                                            marginTop: 20,
                                        }}
                                    >
                                        LANGUAGE PAIRS
                                    </Form.Label>
                                    <Select
                                        onChange={(option) => {
                                            setLanguageFrom(option)
                                        }}
                                        placeholder={'From'}
                                        value={languageFrom}
                                        options={languageSelection}
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                cursor: 'pointer',
                                            }),
                                            control: (provided) => ({
                                                ...provided,
                                                borderColor: '#e6e6e6',
                                            }),
                                            container: (provided) => ({
                                                ...provided,
                                                width: '100%',
                                            }),
                                        }}
                                        components={{
                                            DropdownIndicator,
                                            IndicatorSeparator: () => null,
                                        }}
                                    />
                                    <div
                                        style={{
                                            width: '100%',
                                            height: 5,
                                        }}
                                    ></div>
                                    <Select
                                        onChange={(option) => {
                                            setLanguageTo(option)
                                        }}
                                        placeholder={'To'}
                                        value={languageTo}
                                        options={languageSelection}
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                cursor: 'pointer',
                                            }),
                                            control: (provided) => ({
                                                ...provided,
                                                borderColor: '#e6e6e6',
                                            }),
                                            container: (provided) => ({
                                                ...provided,
                                                width: '100%',
                                            }),
                                        }}
                                        components={{
                                            DropdownIndicator,
                                            IndicatorSeparator: () => null,
                                        }}
                                    />
                                </div>
                            </InputGroup>

                            <InputGroup className="d-block">
                                <div
                                    style={{
                                        fontFamily: 'Signika',
                                        fontWeight: 'bold',
                                        color: '#1F2041',
                                        marginBottom: 7,
                                        marginTop: 20,
                                    }}
                                >
                                    SPECIALIZATIONS
                                </div>
                                <div
                                    style={{ columnCount: 2, width: '100%' }}
                                    className="d-inline-block text-left my-2"
                                >
                                    {specializationSelection.map((obj) => {
                                        return (
                                            <Form.Check
                                                name="specialization"
                                                type={'checkbox'}
                                                id={obj.id}
                                                label={obj.specialization}
                                                checked={selectedSpecializations.includes(obj.id)}
                                                onChange={(e) => {
                                                    if (selectedSpecializations.includes(obj.id)) {
                                                        let findIndex =
                                                            selectedSpecializations.findIndex(
                                                                (obj) => obj.id,
                                                            )
                                                        let temp = [...selectedSpecializations]
                                                        temp.splice(findIndex, 1)
                                                        setSelectedSpecializations(temp)
                                                    } else {
                                                        setSelectedSpecializations([
                                                            ...selectedSpecializations,
                                                            obj.id,
                                                        ])
                                                    }
                                                }}
                                            />
                                        )
                                    })}
                                </div>
                                <Button
                                    onClick={() => {
                                        runSearchAdvanced()
                                    }}
                                    style={{
                                        fontFamily: 'Signika',
                                        fontWeight: 'bold',
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        width: '100%',
                                        backgroundColor: Palette.PRIMARY,
                                        borderColor: Palette.PRIMARY,
                                        'border-radius': '20px',
                                    }}
                                    className="my-3"
                                >
                                    Browse
                                </Button>
                                <span>
                                    This directory only displays HPI members who opted to publish their profiles.
                                </span>
                            </InputGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Container>
    )
}

export default HomePage
