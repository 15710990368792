import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import React, { useEffect, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import { isMobile } from 'react-device-detect'
import { AiOutlineClose, AiOutlineSearch, MdSearch } from 'react-icons/all'
import { FaAngleDown } from "react-icons/fa"
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Select, { components } from "react-select"

import Palette from '../Palette'
import ResultCard from '../components/ResultCard'
import Language from "../models/Language"
import Member from "../models/Member"
import Specialization from "../models/Specialization"

const drawerWidth = 550

export default function SearchResultPage(props) {
    const history = useHistory()
    const location = useLocation()

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [showFilter, setShowFilter] = useState(false)
    const { keyword } = props.match.params
    const [results, setResults] = useState([])
    const [isLoading, setLoading] = useState(false)

    const {advanced} = useParams()

    let [specializationSelection, setSpecializationSelection] = useState([]);
    let [languageSelection, setLanguageSelection] = useState([]);

    let search = window.location.search;
    let params = new URLSearchParams(search);

    let [query, setQuery] = useState("")

    let [languageFrom, setLanguageFrom] = useState(null)
    let [languageTo, setLanguageTo] = useState(null)

    
    let [HPICertification, setHPICertification] = useState(null)

    let [profession, setProfession] = useState(null)
    let [specializationsId, setSpecializationsId] = useState(null)
    let [selectedSpecializations, setSelectedSpecializations] = useState([])


    const runSearch = () => {
        history.push('/search/advanced?query='+query)
        setShowFilter(false)
    }

    const reset = () => {
        setQuery('') 
   
        setHPICertification(0)
        setProfession(null)
        setLanguageFrom(null)
        setLanguageTo(null)
        setSelectedSpecializations([]) 
        history.push('/search?query=')
    }


    const runSearchAdvanced = () => {
        // console.log("SEARCHING", query, languageFrom, languageTo, specializationsId, profession)
        // console.log(
        //     `/search/advanced?query=${query}&language_id_from=${languageFrom.value}` +
        //     `&language_id_to=${languageTo.value}` +
        //     `&specializations_id=${specializationsId}` +
        //     `&profession=${profession}`
        // )

        history.push(`/search/advanced?query=${query}`+
            (languageFrom ? `&language_id_from=${languageFrom?.value}` : "") +
            (languageTo ? `&language_id_to=${languageTo?.value}` : "") +
            (profession ? `&profession=${profession}` : "") +
            (specializationsId ? `&specializations_id=${specializationsId}` : "") +
            (selectedSpecializations.length > 0 ? `&specialization_ids=${selectedSpecializations}` : "") +
         
            (HPICertification ? `&certification=${HPICertification}` : "")
        )
        setShowFilter(false)
    }

    useEffect(()=>{
        getAllSpecialization()
    },[])

    let specializationModel = new Specialization()
    let languageModel = new Language()

    const getAllSpecialization = async () => {
        try {
            const specializations = await specializationModel.getAllAvailableSpecialization()
            // console.log(specializations)

            setSpecializationSelection(specializations)
        } catch (e) {
            console.log(e)
        }
    }

    const getAllLanguages = async (selectedFrom, selectedTo) => {
        try {
            const languages = await languageModel.getAllLanguages()

            let tempLanguageFrom = null
            let tempLanguageTo = null

            // console.log("finding", selectedFrom, selectedTo)

            setLanguageSelection(languages.map(obj=>{

                if(obj.id == selectedFrom){
                    tempLanguageFrom = {
                        value : obj.id,
                        label : obj.language
                    }
                }

                if(obj.id == selectedTo){
                    tempLanguageTo = {
                        value : obj.id,
                        label : obj.language
                    }
                }

                return{
                    value : obj.id,
                    label : obj.language
                }
            }))

            console.log("finding", selectedFrom, selectedTo, tempLanguageFrom, tempLanguageTo)

            setLanguageTo(tempLanguageTo)
            setLanguageFrom(tempLanguageFrom)

            console.log(languages)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getSearchResult()

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [location.search])

    const getSearchResult = async() =>{
        let memberModel = new Member()
        try{

            console.log("advanced", advanced)

            let query = params.get('query');
            let language_id_from = params.get('language_id_from');
            let language_id_to = params.get('language_id_to');
            let profession = params.get('profession');
            let specializations_id = params.get('specialization_ids');
           
            let certification = params.get('certification');

            console.log("specializations_id", specializations_id)

            if(specializations_id){
                let arr = specializations_id.split(",")
                setSelectedSpecializations(arr.map(obj=>{return parseInt(obj)}))
            }else{
                setSelectedSpecializations([])
            }

            if(query && query !== "null"){
                setQuery(query)
            }else{
                setQuery('')
            }

            if(profession){
                setProfession(profession)
            } else{
                setProfession(null)
            }


            getAllLanguages(language_id_from, language_id_to)

            let result

            console.log("ADVANCED", advanced)

            if(advanced){
                result = await memberModel.searchAdvanced(query, language_id_from, language_id_to, profession, specializations_id, certification)
            }else{
                result = await memberModel.search(query)
                console.log(query);
            }

            setResults(result)
            console.log(result)

        }catch (e) {
            console.log(e)
        }

    }

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={'grey'}/>
                </components.DropdownIndicator>
            )
        )
    }
    
    const filterContent = (
        <>
            <Col md={4} className="p-4 position-sticky shadow-sm" style={{ top: 0, overflowY: isMobile ? 'visible' : 'scroll', height: isMobile ? '100vh' : '90vh' }}>
                        <Form.Control
                            value={query}
                            onChange={(e)=>{
                                setQuery(e.target.value)
                            }}
                            type="text"
                            id="inputPassword5"
                            name="search"
                            placeholder="Search by name or HPI ID Number"
                            className="mx-auto mb-4"
                        />
                        <Button
                            onClick={()=>{
                                runSearch()
                            }}
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: 'bold',
                                paddingTop: 10,
                                paddingBottom: 10,
                                width: '100%',
                                backgroundColor: Palette.PRIMARY,
                                borderColor: Palette.PRIMARY,
                                borderRadius: '20px',
                            }}
                        >
                            SEARCH
                            <MdSearch size={20} />
                        </Button>
{/* 
                        <InputGroup className="my-4 d-block">
                            <div className="d-inline-block text-left">
                             
                                <Form.Check
                                    name="interest"
                                    type={'checkbox'}
                                    id={'checkboxHPI'}
                                    label={'Bersertifikat HPI'}
                                    checked={HPICertification}
                                    onClick={(e) => {
                                        let checkedData
                                        e.target.checked === false ? (checkedData = 0) : (checkedData = 1)
                                        setHPICertification(checkedData)
                                    }}
                                />
                            </div>
                        </InputGroup> */}
                        <InputGroup className="my-4 d-block">
                            <div
                                style={{
                                    fontFamily: 'Signika',
                                    fontWeight: 'bold',
                                    color: '#1F2041',
                                    marginBottom: 7,
                                    marginTop: 20,
                                }}
                            >
                                PROFESSION
                            </div>
                            <div style={{ display: 'grid' }} className="d-inline-block text-left">
                                <Form.Check
                                    //name="profession"
                                    type={'radio'}
                                    id={'Translator'}
                                    label={'Translator'}
                                    value={'Translator'}
                                    checked={profession==='Translator'}
                                    onChange={()=>{
                                        setProfession('Translator')
                                    }}
                                />
                                <Form.Check
                                    //name="profession"
                                    type={'radio'}
                                    id={'Interpreter'}
                                    label={'Interpreter'}
                                    checked={profession==='Interpreter'}
                                    onChange={()=>{
                                        setProfession('Interpreter')
                                    }}
                                />
                                <Form.Check
                                   //name="profession"
                                    type={'radio'}
                                    id={'Both'}
                                    label={'Both'}
                                    checked={profession==='Both'}
                                    onChange={()=>{
                                        setProfession('Both')
                                    }}
                                />
                            </div>
                        </InputGroup>

                        <InputGroup className="my-4 d-block">
                            <div className="d-inline-block text-left w-100">
                                <Form.Label
                                    htmlFor="basic-url"
                                    style={{
                                        fontFamily: 'Signika',
                                        fontWeight: 'bold',
                                        color: '#1F2041',
                                        marginBottom: 7,
                                        marginTop: 20,
                                    }}
                                >
                                    LANGUAGE PAIRS
                                </Form.Label>

                                <Select
                                    onChange={(option) => {
                                        setLanguageFrom(option)
                                    }}
                                    placeholder={'From'}
                                    value={languageFrom}
                                    options={languageSelection}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            cursor: 'pointer',

                                        }),
                                        control: provided => ({
                                            ...provided,
                                            borderColor: '#e6e6e6'
                                        }),
                                        container : provided =>({
                                            ...provided,
                                            width : "100%"
                                        })
                                    }}
                                    components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                />

                                <Select
                                    onChange={(option) => {
                                        setLanguageTo(option)
                                    }}
                                    placeholder={'To'}
                                    value={languageTo}
                                    options={languageSelection}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            cursor: 'pointer',

                                        }),
                                        control: provided => ({
                                            ...provided,
                                            borderColor: '#e6e6e6'
                                        }),
                                        container : provided =>({
                                            ...provided,
                                            width : "100%"
                                        })
                                    }}
                                    components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                />

                            </div>
                        </InputGroup>

                        <InputGroup className="my-4 d-block">
                            <div className="d-inline-block text-left w-100">
                                <Form.Label
                                    htmlFor="basic-url"
                                    style={{
                                        fontFamily: 'Signika',
                                        fontWeight: 'bold',
                                        color: '#1F2041',
                                        marginBottom: 7,
                                        marginTop: 20,
                                    }}
                                >
                                    SPESIALISASI
                                </Form.Label>

                                {
                                    specializationSelection.map((obj)=>{
                                        return <Form.Check
                                            name={"specialization "}
                                            type={'checkbox'}
                                            id={obj.id}
                                            label={obj.specialization}
                                            checked={selectedSpecializations.includes(obj.id)}
                                            onChange={(e)=>{
                                                if (selectedSpecializations.includes(obj.id)) {

                                                    let findIndex = selectedSpecializations.indexOf(obj.id)
                                                    let temp = [...selectedSpecializations]

                                                    if (findIndex > -1) temp.splice(findIndex, 1)
                                                    setSelectedSpecializations(temp)

                                                } else {
                                                    setSelectedSpecializations([...selectedSpecializations, obj.id])
                                                }
                                            }}
                                        />
                                    })
                                }
                                <Button
                                    onClick={()=>{
                                        runSearchAdvanced()
                                    }}
                                    style={{
                                        fontFamily: 'Signika',
                                        fontWeight: 'bold',
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        marginTop : 20,
                                        width: '100%',
                                        backgroundColor: Palette.PRIMARY,
                                        borderColor: Palette.PRIMARY,
                                        borderRadius: '20px',
                                    }}
                                    className="mb-2"
                                >
                                    BROWSE
                                </Button>
                                <Button
                                    onClick={()=>{
                                        reset()
                                    }}
                                    style={{
                                        fontFamily: 'Signika',
                                        fontWeight: 'bold',
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        width: '100%',
                                        backgroundColor: Palette.PRIMARY,
                                        borderColor: Palette.PRIMARY,
                                        borderRadius: '20px',
                                    }}
                                >
                                    RESET
                                </Button>
                            </div>
                        </InputGroup>
                    </Col>
                </>
    )

    return (
        <Container style={{ minHeight: 'calc(100vh - 280px)' }}>
            <Row
                style={{
                    backgroundColor: 'white',
                    borderRadius: 10,
                    paddingLeft: windowWidth < 768 ? 0 : 50,
                    paddingRight: windowWidth < 768 ? 0 : 50,
                    paddingBottom: 10,
                }}
            >
                {!isMobile && filterContent}

                <Col>
                    {isLoading ? (
                        <Spinner
                            style={{
                                width: '4rem',
                                height: '4rem',
                                marginLeft: '47%',
                                marginTop: '5%',
                                marginBottom: '5%',
                            }}
                            animation="border"
                            variant="danger"
                        />
                    ) : results.length === 0 ? (
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                marginTop: 10,
                                marginBottom: 20,
                                height : "100%",
                                display : "flex",
                                alignItems : "center",
                                justifyContent : "center"
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                    color: 'grey',
                                    fontSize: '1.5em',
                                }}
                            >
                                No result found
                            </div>
                        </div>
                    ) : (
                        results.map((result) => {
                            return <ResultCard result={result} />
                        })
                    )}
                    <Drawer
                        variant={isMobile ? 'temporary' : 'persistent'}
                        anchor={'bottom'}
                        open={showFilter}
                    >
                        <div
                            style={{
                                width: windowWidth > drawerWidth ? drawerWidth : windowWidth,
                                display: 'flex',
                                flexDirection: 'column',
                                padding: 10,
                            }}
                            trackYProps={{
                                renderer: (props) => {
                                    const { elementRef, ...restProps } = props
                                    return (
                                        <span
                                            {...restProps}
                                            style={{ ...restProps.style, backgroundColor: 'white' }}
                                            ref={elementRef}
                                            className="trackY"
                                        />
                                    )
                                },
                            }}
                            smoothScrolling={true}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: 20,
                                        flex: 1,
                                        marginLeft: 15,
                                        fontWeight: '700',
                                        color: 'rgb(79, 79, 79)',
                                    }}
                                ></span>

                                <IconButton
                                    onClick={() => {
                                        setShowFilter(false)
                                    }}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    <AiOutlineClose color={Palette.PRIMARY} />
                                </IconButton>
                            </div>
                            {filterContent}
                        </div>
                    </Drawer>
                </Col>
            </Row>
            {isMobile && <div 
                style={{bottom: '40px'}}
                className='position-fixed text-center'>
                    <Button 
                        style={{
                            backgroundColor: Palette.PRIMARY,
                            borderRadius: 5,
                            display: 'inline-block',
                            minWidth: 100,
                            color: 'white',
                            textAlign: 'center',
                            bottom: '40px'
                        }}
                        onClick={() => setShowFilter(true)}>
                        <AiOutlineSearch size={20}/>
                        {' '}
                        SEARCH
                    </Button>
            </div>}
        </Container>
    )
}
